<template>
    <div class="feedback_box" v-loading.fullscreen.lock="showLoading">
        <div class="data_box">
            <el-table
                    class="table"
                    @sort-change="sortChange"
                    border
                    :data="feedbacks"
                    style="width: 100%"
                    @selection-change="feedbackChange">


              <el-table-column
                  type="selection"
                  width="55">
              </el-table-column>
                <el-table-column
                        align="center"
                        prop="id"
                        label="id"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="userIdentify"
                        align="center"
                        label="用户标识"
                        width="180">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="contact"
                        label="联系方式"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="comment"
                        align="center"
                        label="反馈内容"
                        width="400">
                </el-table-column>
                <el-table-column
                        align="center"
                        sortable="custom"
                        prop="time"
                        label="反馈时间"
                        width="180">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="deal"
                    label="处理是否"
                    width="50">
                  <template slot-scope="scope">
                    <p>{{(scope.row.deal == 1?"是":"否")}}</p>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                        min-width="240">
                  <template slot="header" slot-scope="scope">
                      <span>操作</span>
                    <a href="javascript:;" @click="onDeal" class="a_class">处理</a>
                    <a href="javascript:;" @click="getFeedbackList" class="a_class">刷新</a>
                    <a href="javascript:;" @click="delfeedbacks" class="a_class">删除</a>
                  </template>
                    <template slot-scope="scope">
                      <el-button type="primary" size="medium"  @click="onDeal(scope.row.id)">处理</el-button>
                      <el-button type="primary" size="medium" icon="el-icon-view" @click="showDetails(scope.row)"></el-button>
                        <el-button type="danger" size="medium" icon="el-icon-delete" @click="delFeedback(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination
                        background
                        :page-size="5"
                        :current-page="pageNo"
                        layout="prev, pager, next"
                        :page-count="pageSum"
                        @current-change="changePage">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="dialogTitle"
                   top="5vh"
                   :visible.sync="showDialog"
                   :close-on-click-modal="false"
                   width="800px"
                   :destroy-on-close="true"
                   :fullscreen="false">
            <ShowFeedback :feedback="selectFeedback"/>
        </el-dialog>
    </div>
</template>

<script>
    import ShowFeedback from "./ShowFeedback";
    export default {
        name: "Feedback",
        components:{
            ShowFeedback
        },
        data:function(){
            return{
                dialogTitle:"反馈详情",
                showDialog:false,
                showLoading:false,
                feedbacks:[],
                pageNo:1,
                pageCount:20,
                orderType:2,
                pageSum:1,
                selectFeedback:{},
                delFeedbackIds:[],
            }
        },
        methods: {
          onDeal:function (id){
            this.showLoading = true;
            const that = this;
            const params = new URLSearchParams()
            params.append("ids",id)
            this.$request.post("/feedback/dealFeedback",params)
                .then(function (data) {
                  that.showLoading = false;
                  if (data.status === 200){
                    that.$confirm("处理成功", "提示", {
                      confirmButtonText: '确定',
                      showClose:false,
                      showCancelButton:false,
                      center:true,
                      type: 'success'
                    }).then(function () {
                      that.getFeedbackList()
                    })
                  }else {
                    that.showError(data.data)
                  }
                }).catch(function (err) {
              that.showLoading = false;
              that.showMsg("网络错误")
            })
          },
          getFeedbackList: function () {
            this.showLoading = true;
            const that = this;
            const params = new URLSearchParams()
            params.append("isPage", 1)
            params.append("pageNo", this.pageNo)
            params.append("pageCount", this.pageCount)
            params.append("orderType", this.orderType)
            this.$request.post("/feedback/getFeedbackList", params)
                .then(function (data) {
                  if (data.status === 200) {
                    that.feedbacks = data.data.records
                    that.pageSum = data.data.pages
                    that.showLoading = false
                  } else {
                    that.showMsg(data.data)
                  }
                }).catch(function (err) {
              that.showLoading = false
              that.showMsg("网络错误")
            })
          },

          //删除多个反馈
          delfeedbacks:function (){
            this.$confirm('此操作将永久删除选中的文件, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              cancelButtonClass:"messagebox-off-button-color",
              confirmButtonClass:"messagebox-ok-button-color"
            }).then(() => {
              this.delAll()
            }).catch(()=>{});
          },
          /**
           * 删除选中的数据
           */
          delAll:function () {
            if (this.delFeedbackIds === undefined || this.delFeedbackIds.length === 0){
              return
            }
            this.showLoading = true;
            const that = this;
            const params = new URLSearchParams()
            this.delFeedbackIds.forEach((item)=>{
              params.append("ids",item)
            })
            this.$request.post("/feedback/delFeedback",params)
                .then(function (data) {
                  that.showLoading = false;
                  if (data.status === 200){
                    that.$confirm("删除成功", "提示", {
                      confirmButtonText: '确定',
                      showClose:false,
                      showCancelButton:false,
                      center:true,
                      type: 'success'
                    }).then(function () {
                      that.getFeedbackList()
                    })
                  }else {
                    that.showError(data.data)
                  }
                }).catch(function (err) {
              that.showLoading = false;
              that.hideLoading()
              that.showError("网络错误")
            })
          },
           /**
           * 显示错误消息
           * @param title
           */
          showMsg: function (title) {
            this.$confirm(title, "提示", {
              confirmButtonText: '确定',
              showClose: false,
              showCancelButton: false,
              center: true,
              type: 'error'
            })
          },
          changePage: function (pageNo) {
            this.pageNo = pageNo;
            this.getFeedbackList()
          },
          sortChange: function ({column, prop, order}) {
            switch (order) {
              case "ascending":
                //升序
                this.orderType = 1;
                break;
              case "descending":
                //升序
                this.orderType = 2;
                break;
              case null:
                //升序
                this.orderType = 0;
                break;
            }
            this.pageNo = 1;//重置页码
            this.getFeedbackList()
          },
          delFeedback: function (id) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              cancelButtonClass: "messagebox-off-button-color",
              confirmButtonClass: "messagebox-ok-button-color"
            }).then(() => {
              this.del(id)
            }).catch(() => {
            });
          },
          del: function (id) {
            this.showLoading = true;
            const that = this;
            const params = new URLSearchParams()
            params.append("id", id)
            this.$request.post("/feedback/delFeedback", params)
                .then(function (data) {
                  that.showLoading = false;
                  if (data.status === 200) {
                    that.$confirm("删除成功", "提示", {
                      confirmButtonText: '确定',
                      showClose: false,
                      showCancelButton: false,
                      center: true,
                      type: 'success'
                    }).then(function () {
                      that.getFeedbackList()
                    })
                  } else {
                    that.showMsg(data.data)
                  }
                }).catch(function (err) {
              that.showLoading = false;
              that.showMsg("网络错误")
            })
          },
          showDetails: function (feedback) {
            this.selectFeedback = feedback;
            this.showDialog = true;
          },
          feedbackChange: function (data) {
            //清空数组
            this.delFeedbackIds = new Array();
            //遍历选中的重新赋值
            data.forEach((item) => {
              this.delFeedbackIds.push(item.id)
            })
          },
        },
        mounted() {
          this.$bus.$emit("setMenuActiveIndex", "2-1-1");
          this.getFeedbackList()
        }
    }
</script>

<style scoped>
    .feedback_box{
        width: 100%;
        min-height: 100%;
        background: white;
        border-radius: 5px;
        padding: 10px 20px;
    }
    .box_operation{
        width: 100%;
        padding: 20px 0;
    }
    .input_box{
        display: inline-block;
        width: 200px;
        margin: 0 10px;
        margin-top: 10px;
    }
    .btn_box{
        margin-top: 10px;
        height: 40px;
        margin-left: 20px;
        vertical-align: top;
        display: inline-block;
    }
    .input_box_select{
        width: 150px;
    }
    .data_box{
        width: 100%;
    }
    .preview_image{
        width: 80px;
        height: 80px;
    }
    .data_box /deep/ .el-table .warning-row {
        background: oldlace;
    }

    .data_box /deep/ .el-table .success-row {

        background: #f0f9eb;
    }

    .page{
        width: 100%;
        margin: 20px 0;
        text-align: center;
    }
    .a_class{
      color: #409EFF;
      font-size: 14px;
      font-weight: 600;
      margin-left: 30px;
    }
    .table{
      /*position: fixed;*/
      height: 100%;
    }
</style>
