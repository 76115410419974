<template>
    <div class="box">
        <el-menu
                :unique-opened="true"
                :router="true"
                :default-active="activeIndex"
                class="el-menu-vertical-demo">
            <el-submenu index="1">
                <template slot="title">
                    <i class="el-icon-picture-outline"></i>
                    <span>资源管理</span>
                </template>
                <el-menu-item-group>
                    <template slot="title">图片资源</template>
                    <el-menu-item index="1-1-1" :route="{name:'imageManage'}">图片</el-menu-item>
                    <el-menu-item index="1-1-2" :route="{name:'imageCategory'}">图片分类</el-menu-item>
                    <el-menu-item index="1-1-3" :route="{name:'tutorialResource'}">教程分类</el-menu-item>
                    <el-menu-item index="1-1-4" :route="{name:'recycleManage'}">回收站</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                    <template slot="title">其他资源</template>
                    <el-menu-item index="1-2-1" :route="{name:'fontResource'}">字体</el-menu-item>
                    <el-menu-item index="1-2-2" :route="{name:'modelResource'}">模型</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>用户数据</span>
                </template>
                <el-menu-item-group>
                    <template slot="title">反馈</template>
                    <el-menu-item index="2-1-1" :route="{name:'feedback'}">用户反馈</el-menu-item>
                </el-menu-item-group>
              <el-menu-item-group>
                <template slot="title">数据</template>
                <el-menu-item index="2-2-1" :route="{name:'userShare'}">用户上传</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>社区管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="3-1-1" :route="{name:'postManager'}">帖子管理页面</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>系统</span>
            </template>
            <el-menu-item-group>
              <template slot="title">配置</template>
              <el-menu-item index="4-1-1" :route="{name:'appSetting'}">App设置</el-menu-item>
              <el-menu-item index="4-1-2" :route="{name:'searchMatchRules'}">搜索规则</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <template slot="title">异常</template>
              <el-menu-item index="4-21" :route="{name:'exceptionInfo'}">服务器异常</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
    </div>
</template>

<script>
    export default {
        name: "Menu",
        data:function(){
          return{
              activeIndex:""
          }
        },
        mounted() {
            const  that = this;
            this.$bus.$on("setMenuActiveIndex",function (index) {
                that.activeIndex = index
            })
        }
    }
</script>

<style scoped>
    .box{
        width: 100%;
        height: 100%;
    }
    .box  .el-menu /deep/{
        min-height: 100%;
    }
</style>
