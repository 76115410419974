<template>
  <div id="app">
  <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isAlive: true
    }
  },
  methods: {
    reload() {
      this.isAlive = false;
      this.$nextTick(function () {
        this.isAlive = true
      })
    }
  }

}
</script>

<style>
  *{
    padding: 0;
    margin: 0;
    font-family: 微软雅黑, "microsoft yahei", arial, "PingFang SC", helvetica, sans-serif;
    box-sizing:border-box;
  }
  html,body{
    padding: 0;
    margin: 0;
    height: 100%;
  }
  a{text-decoration:none;color: rgb(35, 35, 35);}
  a:hover,a:visited{text-decoration:none;color:rgb(35, 35, 35);}
  ul{
    padding: 0;
    margin: 0;
  }
  li{
    list-style-type :none;
    padding: 0;
    margin: 0;
  }
  input,select,option,textarea{outline: none;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  margin: 0;
  padding: 0;
}
  .messagebox-off-button-color{
    background: rebeccapurple !important;
    color:white !important;
  }
  .messagebox-ok-button-color{
    background: floralwhite !important;
    color:black !important;
  }
</style>
