<template>
  <div class="image_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <div class="input_box">
        <el-cascader
            @change="selectChange"
            v-model="categoryId"
            :options="categoryList"
            :props="{ checkStrictly: true,value:'id',label:'categoryName',children:'childList'}"
            clearable>
        </el-cascader>

<!--        <el-select v-model="categoryId" placeholder="分类" clearable :style="{width: '100%'}" size="big">-->
<!--          <el-option v-for="(item, index) in categoryList" :key="index" :label="item.categoryName"-->
<!--                     :value="item.id" :disabled="item.disabled" >-->
<!--          </el-option>-->
<!--        </el-select>-->
      </div>
      <!--            操作列表-->
      <div class="input_box">
        <el-input placeholder="请输入图片名称" v-model="searchName" class="input-with-select"></el-input>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="startTime"
            type="date"
            placeholder="开始日期">
        </el-date-picker>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="endTime"
            type="date"
            placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>

<!--    <el-dialog :title="dialogTitle"-->
<!--               @close="closeDialog"-->
<!--               top="5vh"-->
<!--               :visible.sync="showDialogtoo"-->
<!--               :close-on-click-modal="false"-->
<!--               width="1200px"-->
<!--               @opened="dialogOpen"-->
<!--               :destroy-on-close="true"-->
<!--               :fullscreen="false">-->
<!--      <ImageBox @postFrom="postFrom"/>-->
<!--    </el-dialog>-->
    <div class="box_operation">
<!--      <div class="btn_box">-->
<!--        <el-button  type="primary" @click="open">点击查询</el-button>-->
<!--      </div>-->
      <div class="btn_box">
        <el-button type="warning" @click="search">搜索</el-button>
      </div>
      <div class="btn_box">
        <el-button type="primary" @click="addImage">添加</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="reset">重置</el-button>
      </div>
      <div class="btn_box">
        <el-button type="danger" @click="getImages">刷新</el-button>
      </div>
      <div class="btn_box">
        <el-button type="info" @click="delImages">删除{{ delImageIds.length }}</el-button>
      </div>
    </div>
    <div class="data_box">
      <el-table
          class="table"
          :data="images"
          style="width: 100%"
          @selection-change="selectAllChange"
          :row-class-name="tableRowClassName"
          @sort-change="sortChange">
        <el-table-column
            type="selection"
            width="55"
        >

        </el-table-column>
        <el-table-column
            align="center"
            prop="id"
            label="id"
            width="60">
        </el-table-column>
        <el-table-column
            align="center"
            label="预览"
            width="90">
          <template slot-scope="scope">
            <a :href="scope.row.url" target="_blank">
              <img :src="scope.row.url" class="preview_image">
            </a>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="130"
            label="分类">
          <template slot-scope="scope">
            <p>{{
                scope.row.firstCategory.categoryName + "-" + scope.row.secondCategory.categoryName +
                "-" + scope.row.thirdCategory.categoryName
              }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="tag"
            label="图片标签"
            width="180">
        </el-table-column>
        <el-table-column
            align="center"
            sortable="custom"
            prop="heat"
            label="热度"
            width="100">
        </el-table-column>
        <el-table-column
            align="center"
            label="大小(kb)"
            width="80">
          <template slot-scope="scope">
            <p>{{ (scope.row.size / 1024).toFixed(2) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="hasTemplateData"
            sortable="custom"
            label="是否有操作数据"
            width="80">
          <template slot-scope="scope">
            <p>{{ scope.row.hasTemplateData === 1 ? "有" : "否" }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="是否加锁"
            width="80">
          <template slot-scope="scope">
            <p>{{ scope.row.isLock === 1 ? "是" : "否" }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="time"
            sortable="custom"
            width="180"
            label="上传时间">
        </el-table-column>
        <el-table-column
            align="center"
            prop="updateTime"
            sortable="custom"
            width="180"
            label="更新时间">
        </el-table-column>
        <el-table-column
            fixed="right"
            width="250"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" icon="el-icon-edit" @click="updateImage(scope.row)"></el-button>
            <el-button type="danger" size="medium" icon="el-icon-delete" @click="delImage(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="changePage">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="1200px"
               @opened="dialogOpen"
               :destroy-on-close="true"
               :fullscreen="false">
      <ImageEdit v-on:saveStatus="saveStatus" :images="selectImages"
                 :categoryList="categoryList" :is-update="isUpdate" :loadData="loadDialogData"/>
    </el-dialog>
  </div>
</template>

<script>
import ImageEdit from "./ImageEdit";
import ImageBox from "@/components/resources/ImageBox";

export default {
  name: "ImageManage",
  components: {
    ImageEdit,
    ImageBox
  },
  data: function () {
    return {
      dialogTitle: "",//弹框标题
      isUpdate: false,//是否时修改图片
      selectImages: {},//当前是修改图片信息时可用
      requestNum: 0,//当前请求数量
      showLoading: false,//是否显示刷新界面
      showDialog: false,//是否显示弹框
      showDialogtoo: false,
      hasLoadImageBox: false,//是否开始加载数据
      images: [],//图片列表
      categoryList: [],//图片分类列表
      categoryId: null,
      hotOrderType: 0,//热度排序规则 0:不排序 1:升序 2:降序
      timeOrderType: 0,//上传时间排序规则 0:不排序 1:升序 2:降序
      updateTimeOrderType: 0,//更新时间排序规则 0:不排序 1:升序 2:降序
      hasTemplateDataOrderType: 0,//是否有操作数据排序 0:不排序 1:升序 2:降序
      searchName: "",//图片名称搜索关键字
      pageNo: 1,//当前页面
      pageSum: 1,//总页码
      pageCount: 20,//每页显示数据量
      startTime: '',
      endTime: "",
      delImageIds: [],
      loadDialogData: false,
      selectcategory: []
    }
  },
  methods: {
    /**
     * 获取当前图片列表
     * */
    getImages: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = new URLSearchParams()
      params.append("isPage", 1)
      params.append("pageNo", this.pageNo)
      params.append("pageCount", this.pageCount)
      params.append("isShowCategory", "1")
      if (this.categoryId == null){
        params.append("categoryId", 0)
      }else {
        params.append("categoryId", this.categoryId)
      }
      params.append("heatOrder", this.hotOrderType)
      params.append("timeOrder", this.timeOrderType)
      params.append("updateTimeOrder", this.updateTimeOrderType)
      params.append("hasTemplateDataOrder", this.hasTemplateDataOrderType)
      params.append("searchName", this.searchName)
      if (this.startTime !== undefined && this.startTime !== "") {
        params.append("startTime", this.startTime.getFullYear() + "-" + (this.startTime.getMonth() + 1) + "-" + this.startTime.getDate())
      }
      if (this.endTime !== undefined && this.endTime !== "") {
        params.append("endTime", this.endTime.getFullYear() + "-" + (this.endTime.getMonth() + 1) + "-" + this.endTime.getDate())
      }
      this.$request.post("/images/getImageList", params)
          .then(function (data) {
            that.images = data.data.records
            that.pageSum = data.data.pages
            that.requestNum--;
            that.hideLoading()
          }).catch(function (err) {
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 调接判断隐藏加载界面
     * */
    hideLoading: function () {
      if (this.requestNum <= 0) {
        this.showLoading = false
      }
    },
    /**
     * 分行设置类名
     * */
    tableRowClassName: function ({row, rowIndex}) {
      // if (rowIndex % 2 === 0) {
      //     return 'warning-row';
      // } else if (rowIndex % 2 !== 0) {
      //     return 'success-row';
      // }
      return '';
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({column, prop, order}) {
      switch (prop) {
        case "heat":
          this.heatSortChange(order)
          break
        case "time":
          this.timeSortChange(order)
          break
        case "updateTime":
          this.updateTimeSortChange(order)
          break
        case "hasTemplateData":
          this.hasTemplateDataSortChange(order)
          break
      }
      this.pageNo = 1;//重置页码
      this.getImages()
    },
    /**
     * 热度排序规则改变
     * @param order //当前排序规则
     */
    heatSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.hotOrderType = 1;
          break;
        case "descending":
          //降序
          this.hotOrderType = 2;
          break;
        case null:
          //不做操作
          this.hotOrderType = 0;
          break;
      }
    },
    /**
     * 根据时间排序
     * @param order 排序规则
     */
    timeSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.timeOrderType = 1;
          break;
        case "descending":
          //升序
          this.timeOrderType = 2;
          break;
        case null:
          //升序
          this.timeOrderType = 0;
          break;
      }
    },
    /**
     * 根据更新时间排序
     * @param order 排序规则
     */

    hasTemplateDataSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.hasTemplateDataOrderType = 1;
          break;
        case "descending":
          //升序
          this.hasTemplateDataOrderType = 2;
          break;
        case null:
          //升序
          this.hasTemplateDataOrderType = 0;
          break;
      }
    },

    /**
     * 根据是否有操作数据排序
     * @param order 排序规则
     */
      updateTimeSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.updateTimeOrderType = 1;
          break;
        case "descending":
          //升序
          this.updateTimeOrderType = 2;
          break;
        case null:
          //升序
          this.updateTimeOrderType = 0;
          break;
      }
    },
    /**
     * 重置界面，情况所有搜索数据
     */
    reset: function () {
      // this.orderType = 0;
      this.searchName = ""
      this.pageNo = 1;
      this.categoryId = 0;
      this.getImages()
    },
    /**
     * 搜索功能
     * 清空当前分页情况
     */
    search: function () {
      this.getImages()
    },
    /**
     * 页码改变时
     * @param pageNo 改变的页码
     */
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getImages();
    },
    /**
     * 获取分类列表
     */
    getCategoryList: function () {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("isPage", 0)
      params.append("level", 1)
      this.$request.post("/imageCategory/getListByPage", params)
          .then(function (data) {
            // that.getCategory("",data.data)
            that.categoryList = data.data
            console.log(that.categoryList)
            that.requestNum--;
            that.hideLoading()
          }).catch(function (err) {
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },

    /**
     * 选项选择时
     * @param data
     */
    selectChange: function (data) {
      if (data.length > 0) {
        this.categoryId = data[data.length - 1]
      } else {
        this.categoryId = 0;
      }
    },

    /**
     * 添加一张图片
     */
    addImage: function () {
      this.dialogTitle = "添加图片信息"
      this.isUpdate = false
      this.showDialog = true
    },
    /**
     * 更新图片信息
     * */
    updateImage: function (selectImages) {
      this.dialogTitle = "修改图片信息"
      this.isUpdate = true
      this.showDialog = true

      selectImages.categoryIds = [selectImages.firstCategory.id, selectImages.secondCategory.id,
        selectImages.thirdCategory.id]

      selectImages.fileList = [{name: selectImages.tag, url: selectImages.url}]
      if (selectImages.materialImage !== null) {
        selectImages.materialList = [{name: selectImages.tag + "预览", url: selectImages.materialImage}]
      } else {
        selectImages.materialList = []
      }

      this.selectImages = selectImages
    },
    /**
     * 图片保存状态
     */
    saveStatus: function (flag) {
      if (flag) {
        this.getImages()
      }
      this.showDialog = false
    },

    /**
     * 弹框关闭时
     */

    closeDialog: function () {
      this.selectImages = {}
      this.loadDialogData = false;
    },
    /**
     * 删除某个图片
     * @param id 图片id
     */
    delImage: function (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    /**
     * 多选回调
     * @param data 选中的对象数据
     */
    selectAllChange: function (data) {
      //清空数组
      this.delImageIds = new Array();
      //遍历选中的重新赋值
      data.forEach((item) => {
        this.delImageIds.push(item.id)
      })
    },
    /**
     * 发出删除一个图片请求
     * @param id
     */
    del: function (id) {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("id", id)
      params.append("state", 1)
      this.$request.post("/images/updateState", params)
          .then(function (data) {
            that.requestNum--;
            that.hideLoading()
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getImages()
              })
            } else {
              that.showError(data.message)
            }
          }).catch(function (err) {
        that.requestNum--;
        that.hideLoading()
        that.showError("网络错误")
      })
    },
    /**
     * 删除多个图片
     */
    delImages: function () {
      this.$confirm('此操作将永久删除选中的文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.delAll()
      }).catch(() => {
      });
    },
    /**
     * 删除选中的数据
     */
    delAll: function () {
      if (this.delImageIds === undefined || this.delImageIds.length === 0) {
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      this.delImageIds.forEach((item) => {
        params.append("ids", item)
      })
      this.$request.post("/images/delImage", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getImages()
              })
            } else {
              that.showError(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false;
        that.hideLoading()
        that.showError("网络错误")
      })
    },
    showError: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    dialogOpen: function () {
      this.loadDialogData = true;
    },
    open:function (){
      this.showDialogtoo = true
    },
    postFrom:function (data){
      console.log(data)
    },
    getCategory:function (name,categorys){
      categorys.forEach(item=>{
        let category = {}
        category.id = item.id
        category.categoryName = name+item.categoryName
        this.categoryList.push(category)
        if (item.childList !== null && item.childList !== undefined && item.childList.length > 0){
          this.getCategory(category.categoryName+"/",item.childList)
        }
      })
    },
    // selectcategoryId:function (categoryList){
    //
    //   for (let i =0 ; i<categoryList.length;i++){
    //     if(categoryList[i].childList ===null)
    //       this.selectcategory.add(this.categoryList[i].id())
    //     return this.categoryList[i].id();
    //     return;
    //   }
    //   return
    // }
  },
  mounted: function () {
    this.$bus.$emit("setMenuActiveIndex", "1-1-1");
    this.getImages()

    this.getCategoryList()
    console.log(this.getCategoryList.categoryId)
  },

}
</script>

<style scoped>
.image_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 10px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 90%;
  margin: 0 auto;
}

.btn_box {
  height: 40px;
  margin-top: 10px;
  margin: 10px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;

}

.preview_image {
  width: 30px;
  height: 30px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}

</style>
