import Vue from 'vue'
import App from './App.vue'
import Request from "./base_js/Request";
import store from "./store"
import VueRouter from "vue-router";
import router from "./router/index"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import el from "element-ui/src/locale/lang/el";
Vue.config.productionTip = false
Vue.prototype.$request = Request
Vue.use(VueRouter)
Vue.use(ElementUI);
router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(sessionStorage.getItem(process.env.VUE_APP_USER_KEY))
  //路由验证,当前是登录时，放行
  if (to.path ==="/" || to.path === "/login"){
      // chrome
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
      next()
  }else {
    if (userInfo !== null){
      // chrome
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
      next()
    }else {
      next('/login')
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const vue = new Vue({
  render: h => h(App),
  store,
  router: router,
  beforeCreate() {
    //添加事件总线
    Vue.prototype.$bus = this
  }
}).$mount('#app');
export default vue
