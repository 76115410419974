import VueRouter from "vue-router";

import Login from "../components/Login";
import Index from "../components/Index";
import MainPage from "../components/MainPage";
import ImageManage from "../components/resources/ImageManage";
import ImageCategory from "../components/resources/ImageCategory";
import FontResource from "../components/resources/FontResource";
import ModelResource from "../components/resources/ModelResource";
import TutorialResource from "../components/resources/TutorialResource";
import Feedback from "../components/userData/Feedback";
import AppSetting from "@/components/setting/AppSetting";
import RecycleManage from "@/components/resources/RecycleManage";
import UserShare from "../components/userData/UserShare";
import ExceptionInfo from "../components/setting/ExceptionInfo";
import SearchMatchRules from "../components/setting/SearchMatchRules";
import PostManager from "../components/post/PostManager";

export default new VueRouter({
    mode: process.env.VUE_APP_MODE,
    base: process.env.VUE_APP_PATH,
    APP_PATH: process.env.VUE_APP_PATH,
    routes: [
        {
            path: "/",
            component: Index
        },
        {
            name: "login",
            path: "/login",
            component: Login
        },
        {
            name: "index",
            path: "/index",
            component: MainPage,
            children: [
                //图片管理
                {
                    name: "imageManage",
                    path: "imageManage",
                    component: ImageManage,
                },
                //回收站
                {
                    name: "recycleManage",
                    path: "recycleManage",
                    component: RecycleManage,
                },
                //分类列表
                {
                    name: "imageCategory",
                    path: "imageCategory",
                    component: ImageCategory,
                },
                //字体列表
                {
                    name: "fontResource",
                    path: "fontResource",
                    component: FontResource,
                },
                //模型列表
                {
                    name: "modelResource",
                    path: "modelResource",
                    component: ModelResource,
                },
                //教程列表
                {
                    name: "tutorialResource",
                    path: "tutorialResource",
                    component: TutorialResource,
                },
                //用户反馈
                {
                    name: "feedback",
                    path: "feedback",
                    component: Feedback,
                },
                //系统设置
                {
                    name: "appSetting",
                    path: "appSetting",
                    component: AppSetting,
                },
                //用户分享
                {
                    name: "userShare",
                    path: "userShare",
                    component: UserShare,
                },
                //系统异常
                {
                    name: "exceptionInfo",
                    path: "exceptionInfo",
                    component: ExceptionInfo,
                },
                //匹配规则
                {
                    name: "searchMatchRules",
                    path: "searchMatchRules",
                    component: SearchMatchRules,
                },
                //社区发帖管理
                {
                    name: "postManager",
                    path: "postManager",
                    component: PostManager,
                }
            ]
        },
    ]
})
