<template>
    <div class="tutorial_box" v-loading.fullscreen.lock="showLoading">
        <div class="box_operation">
            <!--            操作列表-->
            <div class="input_box">
                <el-input placeholder="请输入教程名称" v-model="searchName" class="input-with-select"></el-input>
            </div>
            <div class="input_box">
                <el-input placeholder="请输入教程关键字" v-model="searchKey" class="input-with-select"></el-input>
            </div>
            <div class="input_box">
                <el-select v-model="showMore" placeholder="请选择">
                    <el-option
                            v-for="item in showMores"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="btn_box">
                <el-button type="warning" @click="search">搜索</el-button>
            </div>
            <div class="btn_box">
                <el-button type="primary" @click="addTutorial">添加</el-button>
            </div>
            <div class="btn_box">
                <el-button type="success" @click="reset">重置</el-button>
            </div>
            <div class="btn_box">
                <el-button type="danger" @click="getTutorialList">刷新</el-button>
            </div>
        </div>
        <div class="data_box">
            <el-table
                    class="table"
                    @sort-change="sortChange"
                    border
                    :data="tutorials"
                    style="width: 100%">
                <el-table-column
                        align="center"
                        prop="id"
                        label="id"
                        width="60">
                </el-table-column>
                <el-table-column
                        align="center"
                        label="图片或GIF预览"
                        width="120">
                    <template slot-scope="scope">
                        <a :href="'http://file.baozouptu.intelimeditor.com/'+scope.row.url" target="_blank"
                           v-if="scope.row.url != null&&scope.row.url.length>0 && !scope.row.url.endsWith('mp4')">
                            <img :src="'http://file.baozouptu.intelimeditor.com/'+scope.row.url" class="preview_image"/>
                        </a>
                        <p v-else>暂无</p>
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        label="视频预览"
                        width="120">
                    <template slot-scope="scope">
                        <a :href="'http://file.baozouptu.intelimeditor.com/'+scope.row.videoUrl" target="_blank"
                           v-if="scope.row.videoUrl != null && scope.row.videoUrl.endsWith('mp4')">
                            <video :src="'http://file.baozouptu.intelimeditor.com/'+scope.row.videoUrl"
                                   class="preview_image"/>
                        </a>
                        <p v-else>暂无</p>
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="title"
                        label="图片标题"
                        width="180">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="content"
                        label="内容"
                        width="180">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="keyWord"
                        label="关键字"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="level"
                        align="center"
                        width="80"
                        label="级别">
                </el-table-column>
                <el-table-column
                        align="center"
                        width="80"
                        label="显示更多">
                    <template slot-scope="scope">
                        <p>{{scope.row.isShowMore==1?"是":"否"}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="time"
                        sortable="custom"
                        width="180"
                        label="修改时间">
                </el-table-column>
                <el-table-column
                        width="180"
                        align="center"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="medium" icon="el-icon-edit"
                                   @click="updateTutorial(scope.row)"></el-button>
                        <el-button type="danger" size="medium" icon="el-icon-delete"
                                   @click="delTutorial(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination
                        background
                        :page-size="5"
                        :current-page="pageNo"
                        layout="prev, pager, next"
                        :page-count="pageSum"
                        @current-change="changePage">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="dialogTitle"
                   @close="closeDialog"
                   top="5vh"
                   :visible.sync="showDialog"
                   :close-on-click-modal="false"
                   width="800px"
                   :destroy-on-close="true"
                   :fullscreen="false">
            <TutorialEdit v-on:saveStatus="saveStatus" :tutorial="selectTutorial" :is-update="isUpdate"/>
        </el-dialog>
    </div>
</template>

<script>
    import TutorialEdit from "./TutorialEdit";

    export default {

        name: "TutorialResource",
        components: {
            TutorialEdit
        },
        data: function () {
            return {
                showLoading: false,//是否显示弹框
                tutorials: [],//教程列表
                pageNo: 1,
                pageCount: 20,
                pageSum: 1,
                searchName: "",
                searchKey: "",
                orderType: 2,
                showMore: -1,
                showMores: [
                    {
                        label: "全部",
                        value: -1
                    }, {
                        label: "不显示更多",
                        value: 0
                    }, {
                        label: "显示更多",
                        value: 1
                    }
                ],
                dialogTitle: "",
                selectTutorial: {},
                isUpdate: false,
                showDialog: false,
            }
        },
        methods: {
            //获取真实地址信息
            getRealUrl: function (url) {
                return 'http://file.baozouptu.intelimeditor.com/' + url;
            },
            getTutorialList: function () {
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("isPage", 1)
                params.append("pageNo", this.pageNo)
                params.append("pageCount", this.pageCount)
                params.append("name", this.searchName)
                params.append("key", this.searchKey)
                params.append("orderType", this.orderType)
                if (this.showMore !== -1) {
                    params.append("isShowMore", this.showMore)
                }
                this.$request.post("/tutorial/getTutorialList", params)
                    .then(function (data) {
                        if (data.status === 200) {
                            that.tutorials = data.data.records
                            that.pageSum = data.data.pages
                            that.showLoading = false
                        } else {
                            that.showMsg(data.data)
                        }
                    }).catch(function (err) {
                    console.log(err)
                    that.showLoading = false
                    that.showMsg("网络错误")
                })
            },
            /**
             * 显示错误消息
             * @param title
             */
            showMsg: function (title) {
                this.$confirm(title, "提示", {
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    center: true,
                    type: 'error'
                })
            },
            changePage: function (pageNo) {
                this.pageNo = pageNo;
                this.getTutorialList()
            },
            search: function () {
                this.pageNo = 1;
                this.orderType = 2;
                this.getTutorialList()
            },
            reset: function () {
                this.pageNo = 1;
                this.searchName = "";
                this.searchKey = "";
                this.showMore = -1;
                this.orderType = 2;
                this.getTutorialList()
            },
            /**
             * 排序规则改变
             */
            sortChange: function ({column, prop, order}) {
                switch (order) {
                    case "ascending":
                        //升序
                        this.orderType = 1;
                        break;
                    case "descending":
                        //升序
                        this.orderType = 2;
                        break;
                    case null:
                        //不排序
                        this.orderType = 0;
                        break;
                }
                this.pageNo = 1;//重置页码
                this.getTutorialList()
            },
            closeDialog: function () {
                this.selectTutorial = {}
            },
            saveStatus: function (flag) {
                if (flag) {
                    this.reset();
                    this.getTutorialList()
                }
                this.showDialog = false
            },
            addTutorial: function () {
                this.dialogTitle = "添加教程"
                this.isUpdate = false
                this.showDialog = true
            },
            updateTutorial: function (tutorial) {
                if (tutorial.url != null) {
                    tutorial.fileList = [{
                        name: this.getRealUrl(tutorial.url),
                        url: this.getRealUrl(tutorial.url)
                    }]
                } else {
                    tutorial.fileList = []
                }
                if (tutorial.videoUrl != null) {
                    tutorial.videoFile = [{
                        name: this.getRealUrl(tutorial.videoUrl),
                        url: this.getRealUrl(tutorial.videoUrl)
                    }]
                } else {
                    tutorial.videoFile = []
                }

                this.dialogTitle = "修改教程"
                this.isUpdate = true
                this.showDialog = true
                this.selectTutorial = tutorial
            },
            delTutorial: function (id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    cancelButtonClass: "messagebox-off-button-color",
                    confirmButtonClass: "messagebox-ok-button-color"
                }).then(() => {
                    this.del(id)
                }).catch(() => {
                });
            },
            /**
             * 删除一个图片
             * @param id
             */
            del: function (id) {
                this.requestNum++;
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("id", id)
                this.$request.post("/tutorial/deleteTutorial", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("删除成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.getTutorialList()
                            })
                        } else {
                            that.showMsg(data.data);
                        }
                    }).catch(function (err) {
                    that.showLoading = false
                    that.showMsg("网络错误");
                })
            }
        },
        mounted() {
            this.$bus.$emit("setMenuActiveIndex", "1-1-3");
            this.getTutorialList()
        }
    }
</script>

<style scoped>
    .tutorial_box {
        width: 100%;
        min-height: 100%;
        background: white;
        border-radius: 5px;
        padding: 10px 20px;
    }

    .box_operation {
        width: 100%;
        padding: 20px 0;
    }

    .input_box {
        display: inline-block;
        width: 200px;
        margin: 0 10px;
        margin-top: 10px;
    }

    .btn_box {
        margin-top: 10px;
        height: 40px;
        margin-left: 20px;
        vertical-align: top;
        display: inline-block;
    }

    .input_box_select {
        width: 150px;
    }

    .data_box {
        width: 100%;
    }

    .preview_image {
        width: 80px;
        height: 80px;
    }

    .data_box /deep/ .el-table .warning-row {
        background: oldlace;
    }

    .data_box /deep/ .el-table .success-row {
        background: #f0f9eb;
    }

    .page {
        width: 100%;
        margin: 20px 0;
        text-align: center;
    }

    .table {
        /*position: fixed;*/
        height: 100%;
    }
</style>
