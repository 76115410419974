<template>
  <div data-uid="0"
       data-request-app="Web"
       data-request-hash="6cfb0f4aqxbss9"
       style="margin: 0;height: 100%;">
    <div>
      <remote-css href='/css/weui.min.css'></remote-css>
      <remote-css href="/css/animate.min.css"></remote-css>
      <remote-css href="/css/swiper-bundle.min.css"></remote-css>

      <remote-js src="/dist/weui.min.js"></remote-js>
      <remote-js src="/dist/swiper-bundle.min.js"></remote-js>
    </div>
    <div id="main">
      <div id="header" style="padding: 20px 0px;" googl="true">
        <div class="main" style="width: 950px;">
          <a href="/">
            <span id="header-logo"
                  style="display: flex;justify-content: left;align-items: center;"
            >
              <img style="height: 48px;margin-right: 10px;"
                   src="../assets/icon.png"
                   alt=""
              />
              <span>艺术美图</span>
            </span>
          </a>
          <div>
            <a id="header-developer" class="share" @click="copy">
              <span style="font-size: 16px;">分享此应用</span>
            </a>
            <a class="header-developer" target="_blank" href="agreement/privacyPolicy.html">
              <span style="font-size: 16px;">隐私政策</span>
            </a>
          </div>
        </div>
      </div>
      <div id="content">
        <div id="first_screen">
          <div id="main-content" googl="true">
            <div class="text-group">
              <div class="weui-flex logo-box">
                <div class="weui-flex__item">
                  <img
                      class="logo"
                      src="../assets/icon.png"
                      alt="艺术美图"
                  />
                </div>
              </div>
              <div class="weui-flex">
                <div class="weui-flex__item">
                  <p class="title">艺术美图</p>
                  <p class="sub-title mobile-display">
                    艺术美图是一款基于人工智能技术开发的绘画应用。它可以通过智能算法分析用户的绘画风格和画面构成，提供高度个性化的绘画体验。
                    <br/>
                    除此之外，艺术美图还提供了大量的绘画模板，让您可以轻松创作出各种类型的艺术作品。
                    <br/>
                    艺术美图还支持社交分享功能，您可以将您的作品分享给朋友、家人和社交平台上的粉丝们，展示您的创意和天赋。
                    <br/>
                    赶快下载艺术美图，展现您的艺术天赋，创作出您独特的艺术作品！
                  </p>
                  <p class="sub-title pc-display">
                    艺术美图是一款基于人工智能技术开发的绘画应用。它可以通过智能算法分析用户的绘画风格和画面构成，提供高度个性化的绘画体验。
                    <br/>
                    除此之外，艺术美图还提供了大量的绘画模板，让您可以轻松创作出各种类型的艺术作品。
                    <br/>
                    艺术美图还支持社交分享功能，您可以将您的作品分享给朋友、家人和社交平台上的粉丝们，展示您的创意和天赋。
                    <br/>
                    赶快下载艺术美图，展现您的艺术天赋，创作出您独特的艺术作品！
                  </p>
                </div>
              </div>
            </div>
            <div class="weui-flex image-group">
              <div class="weui-flex__item large-image-box">
                <div class="swiper-container swiper-container-initialized swiper-container-horizontal"
                >
                  <div
                      class="swiper-wrapper"
                      style="transition-duration: 0ms; transform: translate3d(-2250px, 0px, 0px);"
                      id="swiper-wrapper-28813a616f10a43ca"
                      aria-live="on"
                  >
                    <div class="swiper-slide swiper-slide-prev" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_1.jpg"
                          alt=""
                      />
                    </div>
                    <div class="swiper-slide swiper-slide-active" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_2.jpg"
                          alt=""
                      />
                    </div>
                    <div class="swiper-slide swiper-slide-next" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_3.jpg"
                          alt=""
                      />
                    </div>
                    <div class="swiper-slide swiper-slide-next" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_4.jpg"
                          alt=""
                      />
                    </div>

                  </div>
                  <!-- 如果需要分页器 -->
                  <div class="swiper-pagination swiper-pagination-bullets">
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"
                    ></span>
                  </div>
                  <span
                      class="swiper-notification"
                      aria-live="assertive"
                      aria-atomic="true"
                  >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer" styld="z-index: 10;">
        ©2023 艺术美图团队 All Rights Reserved.
        <el-link style="color: white;margin-left: 2px;" href="https://beian.miit.gov.cn/#/Integrated/index"
                 target="_blank"
        >
          渝ICP备2023003238号
        </el-link>
        <el-link style="color: white"
                 href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002503755"
                 target="_blank"
        >
          <img style="height: 14px;margin-right: 4px;margin-left: 8px;"
               src="../assets/beian.png"
               alt=""
          />
          渝公网安备 50019002503755号
        </el-link>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Index",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    "remote-css": {
      render(createElement) {
        return createElement("link", {
          attrs: {rel: "stylesheet", href: this.href},
        });
      },
      props: {
        href: {type: String, required: true},
      },
    },
    // eslint-disable-next-line vue/no-unused-components
    "remote-js": {
      render(createElement) {
        return createElement("script", {
          attrs: {type: "text/javascript", src: this.src},
        });
      },
      props: {
        src: {type: String, required: true},
      }
    },
  },
  methods: {
    copy: function () {
      var clipboard = document.createElement("input")
      clipboard.value = "我正在使用艺术美图，感觉非常不错，快去应用商店搜索下载吧！"
      document.body.appendChild(clipboard)
      clipboard.select()
      document.execCommand("copy")
      weui.toast("链接已复制，快分享给小伙伴吧！", 3000);
      document.body.removeChild(clipboard)
    }
  }
}
</script>

<style scoped>
@import "../../public/statics/index.css";
</style>
