<template>
    <div class="category_box" v-loading.fullscreen.lock="showLoading">
        <div class="box_operation">
            <!--            操作列表-->
            <div class="input_box">
                <el-input placeholder="请输入分类名称" v-model="searchName" class="input-with-select"></el-input>
            </div>
            <div class="input_box">
                <el-select v-model="selectLevel" placeholder="请选择">
                    <el-option
                            v-for="item in levels"
                            :key="item.level"
                            :label="item.label"
                            :value="item.level">
                    </el-option>
                </el-select>
            </div>
            <div class="btn_box">
                <el-button type="warning"  @click="search">搜索</el-button>
            </div>
            <div class="btn_box">
                <el-button type="primary"  @click="addCategory(0)">添加</el-button>
            </div>
            <div class="btn_box">
                <el-button type="success"  @click="reset">重置</el-button>
            </div>
            <div class="btn_box">
                <el-button type="danger"  @click="getCategoryList">刷新</el-button>
            </div>
        </div>
        <div class="data_box">
            <el-table
                    :data="categoryList"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    border
                    :tree-props="{children: 'childList', hasChildren: false}">
                <el-table-column
                        prop="id"
                        label="分类id"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="categoryName"
                        label="分类名">
                </el-table-column>
                <el-table-column
                        prop="introduce"
                        label="分类简介">
                </el-table-column>
                <el-table-column
                        prop="level"
                        label="分类等级">
                </el-table-column>
                <el-table-column
                        min-width="240"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button @click="addCategory(scope.row.id)" type="primary" size="small" v-show="scope.row.level<3">添加子分类</el-button>
                        <el-button @click="updateCategory(scope.row)" type="success" size="small" icon="el-icon-edit"></el-button>
                        <el-button @click="delCategory(scope.row.id)" type="danger" size="small" icon="el-icon-delete"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination
                        background
                        :page-size="5"
                        :current-page="pageNo"
                        layout="prev, pager, next"
                        :page-count="pageSum"
                        @current-change="changePage">
                </el-pagination>
            </div>
    </div>
        <el-dialog :title="dialogTitle"
                   @close="closeDialog"
                   top="5vh"
                   :visible.sync="showDialog"
                   :close-on-click-modal="false"
                   width="30%"
                   :destroy-on-close="true"
                   :fullscreen="false">
            <ImageCategoryEdit v-on:saveStatus="saveStatus"  :parentId="parentId" :category="selectCategory" :is-update="isUpdate"/>
        </el-dialog>
    </div>
</template>

<script>
    import ImageCategoryEdit from "./ImageCategoryEdit";
    export default {
        name: "ImageCategory",
        components:{
            ImageCategoryEdit
        },
        data:function () {
            return{
                showDialog:false,//是否显示弹框
                dialogTitle:"",//弹框标题
                parentId:0,//当前选择的分类父类id
                selectCategory:{},//当前选择的分类
                showLoading:false,
                isUpdate:false,//是否是更新
                searchName:"",//搜索的分类名
                selectLevel:1,//当前选择的分类等级
                levels:[
                    // {
                    //     label:"所有分类",
                    //     level:0,
                    // },
                    {
                        label:"一级分类",
                        level:1,
                    },
                    {
                        label:"二级分类",
                        level:2,
                    },
                    {
                        label:"三级分类",
                        level:3,
                    }
                ],
                categoryList:[],
                pageNo:1,//当前页面
                pageSum:1,//总页码
                pageCount:20,//每页显示数据量
            }
        },
        methods:{
            /**
             * 获取分类列表
             */
            getCategoryList:function () {
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("isPage", 1)
                params.append("pageNo", this.pageNo)
                params.append("pageCount", this.pageCount)
                params.append("categoryName", this.searchName)
                params.append("level", this.selectLevel)
                this.$request.post("/imageCategory/getListByPage", params)
                    .then(function (data) {
                        that.categoryList = data.data.records
                        that.pageSum = data.data.pages
                        that.showLoading = false
                    }).catch(function (err) {
                        that.$confirm("网络错误", "提示", {
                            confirmButtonText: '确定',
                            showClose: false,
                            showCancelButton: false,
                            center: true,
                            type: 'error'
                        })
                        that.showLoading = false
                })
            },
            /**
             * 搜索
             * */
            search:function(){
                this.pageNo = 1;
                this.getCategoryList();
            },
            /**
             * 重置搜索选项
             */
            reset:function(){
                this.pageNo = 1;
                this.searchName = "";
                this.getCategoryList();
            },
            /**
             * 页码改变时
             * @param pageNo 改变的页码
             */
            changePage:function (pageNo) {
                this.pageNo = pageNo;
                this.getCategoryList();
            },
            /**
             * 添加一个分类
             * @param id
             */
            addCategory:function(id){
                this.dialogTitle = "添加分类"
                this.parentId = id;
                this.isUpdate = false
                this.showDialog = true;
            },
            /**
             * 根据分类
             * @param id
             */
            updateCategory:function (category) {
                this.selectCategory = category
                this.isUpdate = true
                this.showDialog = true
            },
            /**
             * 删除一个分类
             * @param id
             */
            delCategory:function (id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                  cancelButtonClass:"messagebox-off-button-color",
                  confirmButtonClass:"messagebox-ok-button-color"
                }).then(() => {
                    this.del(id)
                }).catch(()=>{});
            },
            del:function(id){
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("id", id)
                this.$request.post("/imageCategory/delCategory", params)
                    .then(function (data) {
                        that.showLoading = false
                         if (data.status === 200){
                             that.$confirm("删除成功", "提示", {
                                 confirmButtonText: '确定',
                                 showClose:false,
                                 showCancelButton:false,
                                 center:true,
                                 type: 'success'
                             }).then(function () {
                                 that.getCategoryList()
                             })
                         }else{
                             that.$confirm(data.data, "提示", {
                                 confirmButtonText: '确定',
                                 showClose: false,
                                 showCancelButton: false,
                                 center: true,
                                 type: 'error'
                             })
                         }
                    }).catch(function (err) {
                        that.$confirm("网络错误", "提示", {
                            confirmButtonText: '确定',
                            showClose: false,
                            showCancelButton: false,
                            center: true,
                            type: 'error'
                        })
                        that.showLoading = false
                })
            },
            /**
             * 弹框关闭回调
             */
            closeDialog:function () {
                this.parentId = 0;
                this.selectCategory = {}
            },
            /**
             * 当前保存状态
             * @param flag
             */
            saveStatus:function (flag) {
                if (flag){
                    this.getCategoryList()
                }
                this.showDialog = false;
            }
        },
        mounted() {
            this.$bus.$emit("setMenuActiveIndex","1-1-2");
            this.getCategoryList()
        }
    }
</script>

<style scoped>
    .category_box{
        width: 100%;
        min-height: 100%;
        background: white;
        border-radius: 5px;
        padding: 10px 20px;
    }
    .box_operation{
        width: 100%;
        padding: 20px 0;
    }
    .input_box{
        display: inline-block;
        width: 200px;
        margin: 0 10px;
        margin-top: 10px;
    }
    .btn_box{
        height: 40px;
        margin-left: 20px;
        margin-top: 10px;
        vertical-align: top;
        display: inline-block;
    }
    .input_box_select{
        width: 150px;
    }
    .data_box{
        width: 100%;
    }
    .preview_image{
        width: 80px;
        height: 80px;
    }
    .data_box /deep/ .el-table .warning-row {
        background: oldlace;
    }

    .data_box /deep/ .el-table .success-row {
        background: #f0f9eb;
    }
    .page{
        width: 100%;
        margin: 20px 0;
        text-align: center;
    }
</style>
